<app-qms-app-bar [isNomalType]="false" [logoSvgPath]="headerLogoPath" [rightIcons]="rightIcons"
  [hideMenuDropdown]="hideMenuDropdown" title="{{'SIDE_BAR.MONITORING' | translate}}"
  (onRightIconClickedEvent)="rightIconClick($event)" (onMenuClickedEvent)="menuAppbarClick()"
  class="monitor-app-bar-container">
  <button [hidden]="true" [matMenuTriggerFor]="menu" xPosition="after" yPosition="below">
  </button>
  <mat-menu #menu="matMenu" class="monitor-app-bar-menu" (click)="menuClick($event)">
    <qms-list class="app-menu-account" *ngIf="rightIcon === 'account_circle'">
      <qms-list-item>
        <div qms-list-header>
          <div qms-line color="overline" size="lg">{{'SIDE_BAR.MONITORING' | translate}}</div>
        </div>
        <button qms-btn-text class="ps-2 pe-2" (click)="logout()">
          {{'AUTH.LOG_OUT' | translate}}
          <mat-icon>logout</mat-icon>
        </button>
      </qms-list-item>
      <qms-list-item>
        <div qms-list-header>
          <div qms-list-header>
            <div qms-line type="subtitle" size="lg">{{user.firstName ?? user.email}}</div>
            <div qms-line>{{userRole}}</div>
          </div>
        </div>
      </qms-list-item>
      <qms-list-item *ngIf="!hideChangePasswordButton">
        <button qms-btn-text class="ps-2 pe-2" (click)="changePassword()">
          {{'AUTH.CHANGE_PASSWORD' | translate}}
        </button>
      </qms-list-item>
    </qms-list>
    <app-language *ngIf="rightIcon == 'language'"></app-language>
  </mat-menu>
</app-qms-app-bar>
