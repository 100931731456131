import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Pages } from '../../common/constants/page.constant';
import { ToastService } from '../../common/services/toast.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      ToastService.error(this.translate.instant('ERROR.401'));
      this.authService.removeTokens();
      this.router.navigate([`/${Pages.auth}/${Pages.login}`], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}
