export const environment = {
  production: true,
  apiUrl: 'https://monitor-api.k.netpower.cloud/api/',
  assetsPath: '/assets',
  globalLoading: true,
  pageSize: 10,
  defaultLanguage: 'no',
  version: '1.24.1.0',
  ssoLoginUrl: 'https://auth.netpower-kvalitet.no/Account/SignIn?returnUrl=',
};
