import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FormatDateTimeType } from "../enums/format-datetime-type.enum";
import { formatDateFromUTCToLocal } from "./datetime-helper";

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gmi;
    return !urlRegex.test(value) ? { url: true } : null;
  }
}

export function confirmedValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.parent?.get('password')?.value;
  const passwordConfirmation = control.value;
  return password === passwordConfirmation ? null : { confirmPasswordNotMatch: true }
}

export function dateFormGroupValidator(
  fromDateControlName: string,
  toDateControlName: string,
  formatDatetime: FormatDateTimeType = FormatDateTimeType.Date
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (group instanceof FormGroup) {
      const toDateControl = group.get(toDateControlName);

      if (!toDateControl) {
        return null;
      }
      if (!toDateControl.value) {
        return null;
      }

      const toDate = formatDateFromUTCToLocal(toDateControl.value, formatDatetime);
      const currentDate = formatDateFromUTCToLocal(new Date(), formatDatetime);
      if (toDate < currentDate) {
        toDateControl.setErrors({ mustBeGreaterCurrentDate: true });
      }

      const fromDateControl = group.get(fromDateControlName);
      if (!fromDateControl) {
        return null;
      }

      if (!fromDateControl.value) {
        return null;
      }

      const fromDate = formatDateFromUTCToLocal(fromDateControl.value, formatDatetime);

      if (toDate < fromDate) {
        toDateControl.setErrors({ mustBeGreaterFromDate: true });
      }
    }
    return null;
  }
}
