import { OnDestroy, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppInjector } from './services/app-injector.service';

@Injectable()
export class BaseComponent implements OnDestroy {
  ngUnsubscribe = new Subject<void>();
  protected injector;
  translate: TranslateService;
  constructor(){
    this.injector = AppInjector.getInjector();
    this.translate = this.injector.get(TranslateService);
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get environment() {
    return environment;
  }
}
