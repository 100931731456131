/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PagingParameterModel } from '../models/paging-parameter.model';


@Injectable()
export class BaseService {
  defaultHeaders = new HttpHeaders();

  constructor(public http: HttpClient) {

  }

  protected buildPagingParameters(parameters: PagingParameterModel<any>) {
    const params: any = {};
    if (parameters.pageIndex > -1) {
      params["PageIndex"] = parameters.pageIndex;
    }
    if (parameters.pageSize > -1) {
      params["PageSize"] = parameters.pageSize;
    }
    if (!!parameters.orderBy) {
      if (parameters.orderBy.direction) {
        params["OrderBy.Direction"] = parameters.orderBy.direction;
      }
      if (parameters.orderBy.name) {
        params["OrderBy.Name"] = parameters.orderBy.name;
      }
    }
    if (!!parameters.filter) {
      Object.keys(parameters.filter).forEach((propName) => {
        let key = "Filter." + propName;
        if (parameters.filter[propName] != null && parameters.filter[propName] != null) {
          params[key] = parameters.filter[propName];
        }
      });
    }
    return params;
  }

  protected get(url: string, params: any = {}): Observable<any> {
    return this.http.get(environment.apiUrl + url, { headers: this.defaultHeaders, params });
  }

  protected post(url: string, data: any, customHeaders: any = null): Observable<any> {
    let headers: any = this.defaultHeaders;
    if (customHeaders) {
      headers = Object.assign(this.defaultHeaders, customHeaders);
    }
    return this.http.post(environment.apiUrl + url, data, { headers: headers });
  }

  protected put(url: string, data: any): Observable<any> {
    return this.http.put(environment.apiUrl + url, data, { headers: this.defaultHeaders });
  }

  protected delete(url: string, params: any = {}): Observable<any> {
    return this.http.delete(environment.apiUrl + url, { headers: this.defaultHeaders, params });
  }
}
