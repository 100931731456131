import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Api } from '../../common/constants/api.constants';
import { OrderDirection } from '../../common/enums/order-direction.enum';
import { PagingResultModel } from '../../common/models/paging-result.model';
import { ResponseModel } from '../../common/models/response.model';
import { UserModel } from '../../common/models/user.model';
import { BaseService } from '../../common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  public getUsers(
    pageIndex: number = 0,
    pageSize: number = environment.pageSize,
    orderByName: string = '',
    orderByDirection: OrderDirection = OrderDirection.Ascending,
    searchText: string| null = null
    ) : Observable<PagingResultModel<UserModel[]>> {
    const params = this.buildPagingParameters({
      pageIndex: pageIndex,
      pageSize: pageSize,
      orderBy: {
        name: orderByName,
        direction: orderByDirection
      },
      filter: {
        searchText: searchText
      }
    });
    return this.get(Api.user.base, {
      ...params
    });
  }

  public getUser(id: string): Observable<ResponseModel<UserModel>> {
    const url = Api.user.get.replace(Api.user.id, id);
    return this.get(url);
  }

  public createUser(user: UserModel): Observable<ResponseModel<UserModel>> {
    return this.post(Api.user.base, { ...user});
  }

  public updateUser(id: string, user: UserModel): Observable<ResponseModel<UserModel>> {
    const url = Api.user.update.replace(Api.user.id, id);
    return this.put(url, { ...user});
  }

  public deleteUser(id: string): Observable<ResponseModel<ResponseModel<boolean>>> {
    const url = Api.user.delete.replace(Api.user.id, id);
    return this.delete(url);
  }
}
