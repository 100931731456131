import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../common/services/toast.service';
import { RefreshTokenModel } from '../../common/models/refresh-token.model';
import { ResponseModel } from '../../common/models/response.model';
import { Pages } from '../../common/constants/page.constant';
import { Api } from '../../common/constants/api.constants';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    public router: Router
  ) { }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      if (this.authService.getRefreshToken()) {
        return this.authService.refreshToken().pipe(
          switchMap((res: ResponseModel<RefreshTokenModel>) => {
            this.isRefreshing = false;
            if (res.data.token) {
              this.refreshTokenSubject.next(res.data.token);
              return next.handle(this.addTokenHeader(request, res.data.token))
            } else {
              return this.redirectToLoginPage();
            }
          })
        );
      } else {
        return this.redirectToLoginPage();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  redirectToLoginPage() {
    this.isRefreshing = false;
    this.authService.removeTokens();
    this.router.navigate([`/${Pages.auth}/${Pages.login}`]);
    return of();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes(`/${Api.auth.revokeToken}`) || request.url.includes(`/${Api.auth.refreshToken}`)) {
          return this.redirectToLoginPage();
        }
        else if (error.status === 401 && !request.url.includes(`/${Api.auth.login}`)) {
          return this.handle401Error(request, next);
        }
        else if (error.status !== 401){
          let errorMessage = this.translate.instant(`ERROR.${error.status}`);
          if (!errorMessage || errorMessage === `ERROR.${error.status}`) {
            errorMessage = this.translate.instant(`ERROR.UNKNOW_ERROR`);
          }
          ToastService.error(errorMessage);
        }
        return throwError(error);
      })
    );
  }
}
