
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticatedUserViewModel } from '../../auth/models/authenticated-user-view.model';
import { CustomerServicePluginViewModel } from '../../customer/models/customer-service-plugin.model';
import { CustomerViewModel } from '../../customer/models/customer-view.model';
import { CommentType } from '../enums/comment-type.enum';
import { CustomerType } from '../enums/customer-type.enum';
import { CustomerADSyncHistoryModel as CustomerADSyncHistoryPanelModel } from '../models/customer-ad-sync-history-panel.model';
import { CustomerCommentPanelModel } from '../models/customer-comment-panel.model';
import { CustomerPluginInfoPanelModel } from '../models/customer-plugin-info-panel.model';
import { PagingResultModel } from '../models/paging-result.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService extends BaseService {
  getCustomersSubject$ = new BehaviorSubject<PagingResultModel<CustomerViewModel[]>>({ totalRecord: 0, data: [], pageIndex: 0, pageSize: environment.pageSize });
  getFilteredCustomersSubject$ = new BehaviorSubject<string[]>([]);
  getMobileBreakpointSubject$ = new BehaviorSubject<boolean>(false);
  getAuthencatedUserSubject$ = new BehaviorSubject<AuthenticatedUserViewModel>({
    userId: '',
    firstName: '',
    roles: [],
    isAdmin: false,
    ssoUserId: 0,
  });
  reloadDataSubject$ = new Subject<boolean>();
  toggleCommentComponentInRightPanelSubject$ = new BehaviorSubject<CustomerCommentPanelModel>(
    { customerId: '', commentType: CommentType.CustomerInstallation, marginRight: 0, customerName: '', toggle: false }
  );

  togglePluginNoticeComponentInRightPanelSubject$ = new BehaviorSubject<CustomerPluginInfoPanelModel>(
    { pluginInfo: new CustomerServicePluginViewModel(), qmsApiUrl: "", marginRight: 0, toggle: false }
  );

  getStatusCustomerComment$ = new BehaviorSubject<{
    customerId: string,
    hasComment: boolean
  }>({
    customerId: '',
    hasComment: false
  });

  reloadDataPluginSubject$ = new BehaviorSubject<boolean>(false);

  toggleADSyncHistoryRightPanelSubject$ = new BehaviorSubject<CustomerADSyncHistoryPanelModel>(
    { customerId: '', toggle: false }
  );

  customerTypeChangeSubject$ = new Subject<CustomerType>();
  customerCurrentType: CustomerType = CustomerType.Client;
  setCustomers(customers: PagingResultModel<CustomerViewModel[]>) {
    this.getCustomersSubject$.next(customers);
  }

  setFilteredCustomers(customerIds: string[]) {
    this.getFilteredCustomersSubject$.next(customerIds);
  }

  setMobileBreakpoint(isMobile: boolean) {
    this.getMobileBreakpointSubject$.next(isMobile);
  }

  setAuthenticatedUser(user: AuthenticatedUserViewModel) {
    this.getAuthencatedUserSubject$.next(user);
  }

  setReloadData(reload: boolean) {
    this.reloadDataSubject$.next(reload);
  }

  toggleCommentComponentInRightPanel(data: CustomerCommentPanelModel) {
    this.toggleCommentComponentInRightPanelSubject$.next(data);
  }

  togglePluginNoticeComponentInRightPanel(data: CustomerPluginInfoPanelModel) {
    this.togglePluginNoticeComponentInRightPanelSubject$.next(data);
  }

  setStatusCustomerComment(customerId: string, hasComment: boolean){
    this.getStatusCustomerComment$.next({
      customerId: customerId,
      hasComment: hasComment
    });
  }

  setReloadDataPlugin(reload: boolean) {
    this.reloadDataPluginSubject$.next(reload);
  }

  toggleADSyncHistoryPanel(data: CustomerADSyncHistoryPanelModel) {
    this.toggleADSyncHistoryRightPanelSubject$.next(data);
  }

  setCustomerType(customerType: CustomerType) {
    this.customerTypeChangeSubject$.next(customerType);
    this.customerCurrentType = customerType;
  }
  getCurrentCustomerType() {
    return this.customerCurrentType;
  }
}
