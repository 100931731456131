<div class="change-password-container h-100">
  <div class="w-100">
    <app-monitor-app-bar [hideMenuDropdown]="true" [hideChangePasswordButton]="true"></app-monitor-app-bar>
  </div>
  <div class="change-password">
    <div class="w-400">
      <div class="change-password-header">
        <img [src]="footerLogoPath" alt="logo" />
      </div>
      <div class="change-password-content">
        <div class="change-password-content-header">
          <h1 class="change-password-title">{{'AUTH.CHANGE_PASSWORD' | translate}}</h1>
        </div>
        <div class="change-password-content-body mt-4" [formGroup]="changePasswordForm">
          <mat-form-field qms-form appearance="fill" class="w-100">
            <mat-label>{{'AUTH.NEW_PASSWORD' | translate}}</mat-label>
            <mat-icon class="material-icons-outlined" matPrefix>lock</mat-icon>
            <input matInput [(ngModel)]="password" formControlName="password"
              type="{{showPassword ? 'text': 'password'}}" autocomplete="off">
            <button mat-icon-button matSuffix (click)="togglePasswordType()"
              qms-tool-tip="{{ (showPassword ? 'AUTH.HIDE_NEW_PASSWORD' : 'AUTH.SHOW_NEW_PASSWORD') | translate }}"
              mode="dark">
              <mat-icon class="material-icons-outlined" *ngIf="showPassword">visibility_off</mat-icon>
              <mat-icon class="material-icons-outlined" *ngIf="!showPassword">visibility</mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.get('password')?.hasError('required')">
              {{ 'ERROR.THIS_FIELD_IS_REQUIRED' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field qms-form appearance="fill" class="w-100">
            <mat-label>{{'AUTH.CONFIRM_PASSWORD' | translate}}</mat-label>
            <mat-icon class="material-icons-outlined" matPrefix>lock</mat-icon>
            <input matInput [(ngModel)]="confirmPassword" formControlName="confirmPassword"
              type="{{showConfirmPassword ? 'text': 'password'}}" autocomplete="off">
            <button mat-icon-button matSuffix (click)="toggleConfirmPasswordType()"
              qms-tool-tip="{{ (showConfirmPassword ? 'AUTH.HIDE_CONFIRM_PASSWORD' : 'AUTH.SHOW_CONFIRM_PASSWORD') | translate }}"
              mode="dark">
              <mat-icon class="material-icons-outlined" *ngIf="showConfirmPassword">visibility_off</mat-icon>
              <mat-icon class="material-icons-outlined" *ngIf="!showConfirmPassword">visibility</mat-icon>
            </button>
            <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required')">
              {{ 'ERROR.THIS_FIELD_IS_REQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('confirmPassword')?.hasError('confirmPasswordNotMatch')">
              {{'AUTH.PASSWORD_CONFIRMATION_DOES_NOT_MATCH_PASSWORD' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="isFailed">
            {{ 'ERROR.USERNAME_OR_PASSWORD_IS_INCORRECT' | translate}}
          </mat-error>
        </div>
      </div>
      <div class="change-password-footer">
        <button type="button" mat-stroked-button (click)="goBack()">
          {{'COMMON.CANCEL' | translate}}
        </button>
        <button type="button" mat-stroked-button color="accent" (click)="changePassword()">
          {{'COMMON.CHANGE' | translate}}
        </button>
      </div>
    </div>
  </div>

</div>
