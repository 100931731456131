import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AppInjector } from "../services/app-injector.service";

@Injectable()
export class AppRegistryIcon{
    public static registrySvgIcon(name: string, urlSvg: string){
        const iconRegistry: MatIconRegistry = AppInjector.getService(MatIconRegistry)
        const sanitizer: DomSanitizer = AppInjector.getService(DomSanitizer);
        iconRegistry.addSvgIcon(name,sanitizer.bypassSecurityTrustResourceUrl(urlSvg))
    }
}
