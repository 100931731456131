import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base-component';
import { FormatDateTimeType } from '../../enums/format-datetime-type.enum';
import { AppRegistryIcon } from '../../helpers/registry-icon';
import { EmailForCustomerDataModel } from '../../models/email-for-customer-data.model';
import { QmsDatePipe } from '../../pipes/qms-date.pipe';

@Component({
  selector: 'app-preview-mail-notify-customer',
  templateUrl: './preview-mail-notify-customer.component.html',
  styleUrls: ['./preview-mail-notify-customer.component.scss']
})
export class PreviewMailNotifyCustomerComponent extends BaseComponent implements OnInit {
  @Input() data: EmailForCustomerDataModel = new EmailForCustomerDataModel();
  currentDateTime = this.qmsPipe.transform(new Date, FormatDateTimeType.DateAndTime);
  constructor(
    private qmsPipe: QmsDatePipe
  ) {
    super();
    AppRegistryIcon.registrySvgIcon('logo', 'assets/icons/logo_template_mail.svg')
  }
  ngOnInit(): void {
  }

}
