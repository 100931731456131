import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';
import decode from 'jwt-decode';
import { parseJwt } from '../../common/helpers/jwt-helper';
import { ToastService } from '../../common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Pages } from '../../common/constants/page.constant';

@Injectable()
export class RoleChildrenGuardService implements CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedRole = childRoute.data['expectedRole'] || '';
    const token = this.authService.getAccessToken();
    const tokenPayload: any = parseJwt(token);
    const isAuthenticated = this.authService.isAuthenticated();
    const isAdmin = tokenPayload.role.toLowerCase() === expectedRole.toLowerCase();
    if (!isAuthenticated || !isAdmin) {
      ToastService.error(this.translate.instant('ERROR.401'));
      this.authService.removeTokens();
      this.router.navigate([`/${Pages.auth}/${Pages.login}`], { queryParams: { returnUrl: childRoute.url } });
      return false;
    }
    return true;
  }
}
