import { NgModule } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatCommonModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  QmsAngularModule,
  QmsAppBarModule,
  QmsBadgesModule,
  QMSBreadcrumbModule,
  QMSButtonModule,
  QMSCKEditorModule,
  QMSEditFileNameDialogModule,
  QMSListModule,
  QMSNavigationDrawerModule,
  QmsPaginatorModule,
  QMSRichTextModule,
  QMSSideSheetModule,
  QMSTableModule,
  TranslateLibraryService
} from 'qms-angular';
import { AppCommonModule } from '../common/app-common.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastService } from '../common/services/toast.service';
import { LoginComponent } from '../auth/login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthGuardService } from './services/auth-guard.service';
import { RoleGuardService } from './services/role-guard.service';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { AuthChildrenGuardService } from './services/auth-children-guard.service';
import { RoleChildrenGuardService } from './services/role-children-guard.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
@NgModule({
  declarations: [
    ChangePasswordComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ClipboardModule,
    CKEditorModule,
    MatCommonModule,
    MatTableModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatListModule,
    MatDividerModule,
    MatInputModule,
    MatSidenavModule,
    MatMenuModule,
    MatExpansionModule,
    MatChipsModule,
    MatCardModule,
    AppCommonModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule,
    ScrollingModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSortModule,
    MatDialogModule,
    MatRadioModule,
    NgbModule,
    NgbDropdownModule,
    QmsAngularModule,
    QMSButtonModule,
    QMSEditFileNameDialogModule,
    QMSListModule,
    QMSRichTextModule,
    QMSTableModule,
    QMSCKEditorModule,
    QmsAppBarModule,
    QMSBreadcrumbModule,
    QmsBadgesModule,
    QmsPaginatorModule,
    QMSNavigationDrawerModule,
    QMSSideSheetModule,
    InfiniteScrollModule
  ],
  exports: [
    ChangePasswordComponent,
    LoginComponent
  ],
  providers: [
    TranslateLibraryService,
    JwtHelperService,
    AuthService,
    AuthGuardService,
    AuthChildrenGuardService,
    RoleGuardService,
    RoleChildrenGuardService,
    TranslateService,
    [{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS }],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    ToastService
  ],
  entryComponents: [
    MatDialogModule,
    MatTableModule,
    NgxMatSelectSearchModule,
    CKEditorModule,
    QmsAngularModule,
    AppCommonModule,
    LoginComponent
  ]
})
export class AuthModule { }
