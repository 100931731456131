<div class="message-box">
  <h1 mat-dialog-title>{{data.title ? data.title : ('COMMON.MESSAGE' | translate)}}</h1>
  <mat-dialog-content>
    <div *ngIf="data.innerHTMLContent">
        {{data.innerHTMLContent}}
    </div>
    <div *ngIf="data.message">
      {{data.message}}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'COMMON.CANCEL' | translate}}</button>
  </mat-dialog-actions>
</div>
