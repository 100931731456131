import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, takeUntil } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';
import { BaseComponent } from '../common/base-component';
import { Constants } from '../common/constants/constant';
import { IconModel } from '../common/models/icon.model';
import { GlobalService } from '../common/services/global.service';
import * as modelMapper from '../common/helpers/model-mapper';
import { AuthenticatedUserViewModel } from '../auth/models/authenticated-user-view.model';
import { Pages } from '../common/constants/page.constant';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
  globalLoading = true;
  accountIcon = new IconModel('account_circle', '');
  languageIcon = new IconModel('language', '');
  rightIcons = [this.languageIcon, this.accountIcon];
  footerLogoPath: string = `//${window.location.host}/assets/images/netpower-logo.png`;
  headerLogoPath: string = `//${window.location.host}/assets/images/netpower-icon-outline.png`;
  isMobile = false;
  year: number = 0;
  isAuthenticated = false;
  user: AuthenticatedUserViewModel = {
    userId: '',
    firstName: '',
    roles: [],
    isAdmin: false
  }
  userRole: string | null = null;
  rightIcon: string = '';

  @ViewChild(MatSidenav) drawer!: MatSidenav;

  constructor(
    private globalService: GlobalService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router
  ) {
    super();
    this.year = new Date().getFullYear();
    this.rightIcons = [this.languageIcon, this.accountIcon];
    if (!this.router.url || this.router.url === "/") {
      this.router.navigate([Pages.home]);
    }
  }

  public get pages() {
    return Pages;
  }

  ngOnInit(): void {
    this.globalLoading = this.environment.globalLoading;
    this.isAuthenticated = !!this.authService.getAccessToken();
    this.getAuthenticatedUser();
    this.breakpointObserver.observe([`(max-width: ${Constants.MAX_MOBILE_WIDTH}px)`]).subscribe((state: BreakpointState) => {
      this.isMobile = !!state.matches;
      this.globalService.setMobileBreakpoint(this.isMobile);
    });
  }

  getAuthenticatedUser() {
    this.authService.getAuthenticatedUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.user = modelMapper.toAuthenticatedUserViewModel(res);
      this.globalService.setAuthenticatedUser(this.user);
    });
  }

  onMenuClicked() {
    if (this.drawer) {
      this.drawer.toggle();
    }
  }
}
