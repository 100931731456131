import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[qmsCkEditorFocus]',
})
export class QmsCkEditorFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    if (el.nativeElement && el.nativeElement.tagName.toLowerCase() != 'qms-ckeditor') {
      throw new Error('Element does not accept focus.');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
        const ckEditable = this.el.nativeElement.querySelector('.ck-editor__editable') as any;
        if (ckEditable && ckEditable.ckeditorInstance) {
          ckEditable.ckeditorInstance.editing.view.focus()
        }
      }, 500);
  }
}
