import { AuthenticatedUserViewModel } from "../../auth/models/authenticated-user-view.model";
import { CustomerDemoViewModel } from "../../customer/models/customer-demo-view.model";
import { CustomerElmahLogViewModel } from "../../customer/models/customer-elmah-log-view.model";
import { CustomerInstallationViewModel } from "../../customer/models/customer-installation-view.model";
import { CustomerServicePluginModel, CustomerServicePluginViewModel } from "../../customer/models/customer-service-plugin.model";
import { CustomerServiceStatusSiteViewModel } from "../../customer/models/customer-service-status-site-view.model";
import { CustomerSiteDatabaseViewModel } from "../../customer/models/customer-site-database-view.model";
import { CustomerSiteStatusViewModel } from "../../customer/models/customer-site-status-view.model";
import { CustomerStatusOfServicesViewModel } from "../../customer/models/customer-status-of-services.model";
import { CustomerViewModel } from "../../customer/models/customer-view.model";
import { UserRoleViewModel } from "../../user/models/user-role-view.model";
import { UserViewModel } from "../../user/models/user-view.model";
import { CustomerServiceModuleStatus, CustomerServiceModuleStatusName } from "../enums/customer-service-module-status.enum";
import { CustomerServicePluginState, CustomerServicePluginStateName } from "../enums/customer-service-plugin-state.enum";
import { CustomerServiceSiteStatus } from "../enums/customer-service-site-status.enum";
import { CustomerServiceSiteType } from "../enums/customer-service-site-type.enum";
import { FormatDateTimeType } from "../enums/format-datetime-type.enum";
import { UserRole } from "../enums/user-role.enum";
import * as datetimeHelper from "../helpers/datetime-helper";
import { AuthenticatedUserModel } from "../models/authenticated-user.model";
import { CommentModel } from "../models/comment.model";
import { CustomerDemoModel } from "../models/customer-demo.model";
import { CustomerElmahLogModel } from "../models/customer-elmah-log.model";
import { CustomerInstallationModel } from "../models/customer-installation.model";
import { CustomerServiceStatusSiteModel } from "../models/customer-service-status-site.model";
import { CustomerSiteDatabaseModel } from "../models/customer-site-database.model";
import { CustomerSiteStatusModel } from "../models/customer-site-status.model";
import { CustomerModel } from "../models/customer.model";
import { ILog } from "../models/log.model";
import { UserRoleModel } from "../models/user-role.model";
import { UserModel } from "../models/user.model";

export function toCustomerModel(model: CustomerViewModel): CustomerModel {
  return {
    id: model.id,
    name: model.name,
    environment: model.environment,
    contactName: model.contactName,
    contactPhoneNumber: model.contactPhoneNumber,
    contactEmail: model.contactEmail,
    activateQRCodeUser: model.activateQRCodeUser,
    customerServiceSites: model.customerServiceSites,
    projectManagerName: model.projectManagerName,
    projectManagerId: model.projectManagerId,
    note: model.note,
    pinNote: model.pinNote,
    limitAccessTime: model.limitAccessTime,
    accessibleDate: model.accessibleDate,
    accessExpireDate: model.accessExpireDate,
    customerType: model.customerType
  }
}

export function toCustomerViewModels(models: CustomerModel[]): CustomerViewModel[] {
  return models.map(i => toCustomerViewModel(i));
}

export function toCustomerViewModel(model: CustomerModel): CustomerViewModel {
    return {
      id: model.id,
      name: model.name,
      environment: model.environment,
      contactName: model.contactName,
      contactPhoneNumber: model.contactPhoneNumber,
      contactEmail: model.contactEmail,
      activateQRCodeUser: model.activateQRCodeUser,
      customerServiceSites: model.customerServiceSites,
      projectManagerName: model.projectManagerName,
      projectManagerId: model.projectManagerId,
      note: model.note,
      pinNote: model.pinNote,
      limitAccessTime: model.limitAccessTime,
      accessibleDate: model.accessibleDate,
      accessExpireDate: model.accessExpireDate,
      customerType: model.customerType
    };
}

function getcustomerServiceSiteTypeName(type: CustomerServiceSiteType) {
  switch (type) {
    case CustomerServiceSiteType.Backend:
      return 'COMMON.CUSTOMER_SERVICE_SITE_TYPE.BACKEND';
    case CustomerServiceSiteType.Handbook:
      return 'COMMON.CUSTOMER_SERVICE_SITE_TYPE.HANDBOOK';
    case CustomerServiceSiteType.Deviation:
      return 'COMMON.CUSTOMER_SERVICE_SITE_TYPE.DEVIATION';
    case CustomerServiceSiteType.Auth:
      return 'COMMON.CUSTOMER_SERVICE_SITE_TYPE.AUTH';
    case CustomerServiceSiteType.BackgroundJobService:
      return 'COMMON.CUSTOMER_SERVICE_SITE_TYPE.BACKGROUND_JOB_SERVICE';
    default:
      return '';
  }
}

function getcustomerServiceSiteStatusName(status: CustomerServiceSiteStatus) {
  switch (status) {
    case CustomerServiceSiteStatus.Automatic:
      return 'COMMON.CUSTOMER_SERVICE_SITE_STATUS.AUTOMATIC';
    default:
      return 'COMMON.CUSTOMER_SERVICE_SITE_STATUS.DISABLED';
  }
}

export function toCustomerSiteDatabaseViewModel(models: CustomerSiteDatabaseModel[]): CustomerSiteDatabaseViewModel[] {
  return models.map(i => {
    return {
      customerId: i.customerId,
      customerName: i.customerName,
      databaseStatus: i.databaseStatus,
      databaseStatusMessage: i.databaseStatusMessage,
      lastSyncTime: i.lastSyncTime,
    }
  });
}

export function toCustomerSiteStatusViewModels(models: CustomerSiteStatusModel[], customerExpandedIds?: string[]): CustomerSiteStatusViewModel[] {
  return models.map(i => {
    return {
      customerId: i.customerId,
      customerName: i.customerName,
      expanded: customerExpandedIds ? customerExpandedIds.includes(i.customerId) : false,
      customerServiceStatusSite: toCustomerServiceStatusSiteModels(i.customerServiceStatusSite),
      numberService: i.customerServiceStatusSite.length
    }
  });
}

export function toCustomerServiceStatusSiteModels(models: CustomerServiceStatusSiteModel[]): CustomerServiceStatusSiteViewModel[] {
  return models.map(i => {
    return {
      domainName: i.domainName,
      siteType: getcustomerServiceSiteTypeName(i.customerServiceSiteType),
      siteStatus: getcustomerServiceSiteStatusName(i.customerServiceSiteStatus),
      httpStatus: i.httpStatus,
      httpStatusMessage: i.httpStatusMessage,
      httpsStatus: i.httpsStatus,
      serviceName: i.serviceName,
      httpsStatusMessage: i.httpsStatusMessage,
      lastSyncTime: i.lastSyncTime,
      serviceDescription: i.serviceDescription
    }
  });
}

export function toCustomerInstallationViewModels(models: CustomerInstallationModel[], customerExpandedIds?: string[]): CustomerInstallationViewModel[] {
  return models.map(i => {
    let backendUrl = '';
    let hasSharepointDomain = false;
    if (i.customerServiceSites) {
      const backendSite = i.customerServiceSites.find(i => i.customerServiceSiteType === CustomerServiceSiteType.Backend);
      if (backendSite) {
        backendUrl = backendSite.domainName;
      }
      if (!backendUrl.startsWith('http://') && !backendUrl.startsWith('https://') && !backendUrl.startsWith('www.')) {
        backendUrl = 'http://' + backendUrl;
      }
      if (i.customerServiceSites.some(ele => ele.customerServiceSiteType === CustomerServiceSiteType.Sharepoint)) {
        hasSharepointDomain = true;
      }
      i.customerServiceSites = i.customerServiceSites.sort((first, second) => {
        if (first.domainName > second.domainName) return 1;
        if (first.domainName < second.domainName) return -1;
        return 0;
      })
    }
    return {
      customerId: i.customerId,
      customerName: i.customerName,
      version: i.version,
      environment: i.environment,
      projectManagerName: i.projectManagerName,
      projectManagerId: i.projectManagerId,
      contactName: i.contactName,
      expanded: customerExpandedIds ? customerExpandedIds.includes(i.customerId) : false,
      contactPhoneNumber: i.contactPhoneNumber,
      contactEmail: i.contactEmail,
      customerServiceSites: i.customerServiceSites,
      enabledAnnualCycleModule: i.enabledAnnualCycleModule,
      enabledChecklistModule: i.enabledChecklistModule,
      enabledDeviationModule: i.enabledDeviationModule,
      enabledDocumentModule: i.enabledDocumentModule,
      enabledRiskModule: i.enabledRiskModule,
      enabledWorkplaceSafetyModule: i.enabledWorkplaceSafetyModule,
      backendUrl: backendUrl,
      activateQRCodeUser: i.activateQRCodeUser,
      hasSharepointDomain: hasSharepointDomain,
      hasComment: i.hasComment,
      statusInstallment: i.statusInstallment,
      scheduleInstallment: i.scheduleInstallment,
      note: i.note,
      pinNote: i.pinNote
    }
  });
}

export function toCustomerElmahLogViewModels(models: CustomerElmahLogModel[]): CustomerElmahLogViewModel[] {
  return models.map(i => {
    return {
      application: i.application,
      host: i.host,
      statusCode: i.statusCode,
      type: i.type,
      message: i.message,
      detail: i.detail,
      user: i.user,
      lastWriteTime: i.lastWriteTime,
      errorId: i.errorId,
      isDeleted: i.isDeleted
    }
  });
}

export function toAuthenticatedUserViewModel(model: AuthenticatedUserModel): AuthenticatedUserViewModel {
  const isAdmin = model.roles && model.roles.filter((i) => i.toLowerCase() == UserRole.Admin.toLowerCase()).length > 0;
  return {
    userId: model.userId,
    firstName: model.firstName,
    email: model.email,
    roles: model.roles,
    isAdmin: isAdmin,
    ssoUserId: model.ssoUserId
  }
}

export function toUserRoleViewModel(model: UserRoleModel): UserRoleViewModel {
  return {
    id: model.id,
    name: model.name
  }
}

export function toUserRoleViewModels(models: UserRoleModel[]): UserRoleViewModel[] {
  return models.map(i => {
    return toUserRoleViewModel(i);
  });
}

export function toUserRoleModel(viewModel: UserRoleViewModel): UserRoleModel {
  return {
    id: viewModel.id,
    name: viewModel.name
  }
}


export function toUserModel(viewModel: UserViewModel): UserModel {
  return {
    id: viewModel.id,
    firstName: viewModel.firstName,
    roleIds: viewModel.roleIds,
    enabledEmailNotification: viewModel.enabledEmailNotification,
    enabledSlackNotification: viewModel.enabledSlackNotification,
    email: viewModel.email,
    password: viewModel.password
  }
}

export function toUserViewModel(model: UserModel): UserViewModel {
  const notifications: string[] = [];
  if (model.enabledEmailNotification) {
    notifications.push('COMMON.EMAIL');
  }
  if (model.enabledSlackNotification) {
    notifications.push('COMMON.SLACK');
  }
  const roles = model.roles ? toUserRoleViewModels(model.roles) : [];
  let createdDate: string | undefined = undefined;
  if (model.createdDate) {
    createdDate = datetimeHelper.formatDateFromUTCToLocal(model.createdDate, FormatDateTimeType.DateAndTime);
  }
  let updatedDate: string | undefined = undefined;
  if (model.updatedDate) {
    updatedDate = datetimeHelper.formatDateFromUTCToLocal(model.updatedDate, FormatDateTimeType.DateAndTime);
  }

  return {
    id: model.id,
    firstName: model.firstName,
    roles: roles,
    roleIds: model.roleIds,
    notifications,
    enabledEmailNotification: model.enabledEmailNotification,
    enabledSlackNotification: model.enabledSlackNotification,
    createdDate,
    updatedDate,
    email: model.email,
  }
}

export function toUserViewModels(models: UserModel[]): UserViewModel[] {
  return models.map(i => {
    return toUserViewModel(i);
  });
}

export function toLogViewModel(model: CommentModel, userId: string): ILog {
  const isDraft = model.creatorId == userId;
  return {
    id: model.id,
    content: model.content,
    user: model.creatorName,
    date: model.createdDate ? datetimeHelper.formatDateFromUTCToLocal(model.createdDate, FormatDateTimeType.DateAndTimeWithOutSeconds) : '',
    editedContent: '',
    isDraft: isDraft,
    isPinned: model.isPinned
  }
}

export function toLogViewModels(models: CommentModel[], userId: string): ILog[] {
  return models.map(i => {
    return toLogViewModel(i, userId);
  });
}

export function toCustomerDemoViewModel(i: CustomerDemoModel): CustomerDemoViewModel {
  let backendUrl = '';
  if (i.customerServiceSites) {
    const backendSite = i.customerServiceSites.find(i => i.customerServiceSiteType === CustomerServiceSiteType.Backend);
    if (backendSite) {
      backendUrl = backendSite.domainName;
    }
    if (!backendUrl.startsWith('htttp://') || !backendUrl.startsWith('htttps://') || !backendUrl.startsWith('www.')) {
      backendUrl = 'http://' + backendUrl;
    }
  }
  return {
    customerId: i.customerId,
    customerName: i.customerName,
    version: i.version,
    environment: i.environment,
    projectManagerName: i.projectManagerName,
    projectManagerId: i.projectManagerId,
    customerServiceSites: i.customerServiceSites,
    enabledAnnualCycleModule: i.enabledAnnualCycleModule,
    enabledChecklistModule: i.enabledChecklistModule,
    enabledDeviationModule: i.enabledDeviationModule,
    enabledDocumentModule: i.enabledDocumentModule,
    enabledRiskModule: i.enabledRiskModule,
    enabledWorkplaceSafetyModule: i.enabledWorkplaceSafetyModule,
    backendUrl: backendUrl,
    type: i.type,
    status: i.status,
    fromDate: i.fromDate,
    toDate: i.toDate,
    fromDateString: datetimeHelper.formatDateFromUTCToLocal(i.fromDate, FormatDateTimeType.Date),
    toDateString: datetimeHelper.formatDateFromUTCToLocal(i.toDate, FormatDateTimeType.Date)
  }
}

export function toCustomerDemoViewModels(models: CustomerDemoModel[]): CustomerDemoViewModel[] {
  return models.map(i => {
    return toCustomerDemoViewModel(i);
  });
}

export function toCustomerDemoModel(i: CustomerDemoViewModel): CustomerDemoModel {
  return {
    customerId: i.customerId,
    customerName: i.customerName,
    version: i.version,
    environment: i.environment,
    projectManagerName: i.projectManagerName,
    projectManagerId: i.projectManagerId,
    customerServiceSites: i.customerServiceSites,
    enabledAnnualCycleModule: i.enabledAnnualCycleModule,
    enabledChecklistModule: i.enabledChecklistModule,
    enabledDeviationModule: i.enabledDeviationModule,
    enabledDocumentModule: i.enabledDocumentModule,
    enabledRiskModule: i.enabledRiskModule,
    enabledWorkplaceSafetyModule: i.enabledWorkplaceSafetyModule,
    type: i.type,
    status: i.status,
    fromDate: i.fromDate,
    toDate: i.toDate
  }
}

export function toCustomerStatusOfServicesViewModels(models: CustomerInstallationModel[], customerExpandedIds?: string[]): CustomerStatusOfServicesViewModel[] {
  return models.map(i => {
    let backendUrl = '';
    let hasSharepointDomain = false;
    if (i.customerServiceSites) {
      const backendSite = i.customerServiceSites.find(i => i.customerServiceSiteType === CustomerServiceSiteType.Backend);
      if (backendSite) {
        backendUrl = backendSite.domainName;
      }
      if (!backendUrl.startsWith('http://') && !backendUrl.startsWith('https://') && !backendUrl.startsWith('www.')) {
        backendUrl = 'http://' + backendUrl;
      }
      if (i.customerServiceSites.some(ele => ele.customerServiceSiteType === CustomerServiceSiteType.Sharepoint)) {
        hasSharepointDomain = true;
      }
      i.customerServiceSites = i.customerServiceSites.sort((first, second) => {
        if (first.domainName > second.domainName) return 1;
        if (first.domainName < second.domainName) return -1;
        return 0;
      })
    }
    return {
      customerId: i.customerId,
      customerName: i.customerName,
      version: i.version,
      environment: i.environment,
      projectManagerName: i.projectManagerName,
      projectManagerId: i.projectManagerId,
      contactName: i.contactName,
      expanded: customerExpandedIds ? customerExpandedIds.includes(i.customerId) : false,
      contactPhoneNumber: i.contactPhoneNumber,
      contactEmail: i.contactEmail,
      customerServiceSites: i.customerServiceSites,
      enabledAnnualCycleModule: i.enabledAnnualCycleModule,
      enabledChecklistModule: i.enabledChecklistModule,
      enabledDeviationModule: i.enabledDeviationModule,
      enabledDocumentModule: i.enabledDocumentModule,
      enabledRiskModule: i.enabledRiskModule,
      enabledWorkplaceSafetyModule: i.enabledWorkplaceSafetyModule,
      backendUrl: backendUrl,
      activateQRCodeUser: i.activateQRCodeUser,
      hasSharepointDomain: hasSharepointDomain,
      hasComment: i.hasComment,
      note: i.note,
      pinNote: i.pinNote
    }
  });
}

export function toCustomerPluginViewModel(model: CustomerServicePluginModel): CustomerServicePluginViewModel {
  return {
    customerId: model.customerId,
    customerName: model.customerName,
    moduleCode: model.moduleCode,
    pluginId: model.pluginId,
    pluginName: model.pluginName,
    serviceStatus: model.serviceStatus,
    servicePluginState: model.servicePluginState,
    serviceStatusView: model.serviceStatus ? CustomerServiceModuleStatus.Corrected : CustomerServiceModuleStatus.Failed,
    serviceStatusName: model.serviceStatus ? CustomerServiceModuleStatusName.Corrected : CustomerServiceModuleStatusName.Failed,
    servicePluginStateView: model.servicePluginState ? CustomerServicePluginState.Enabled : CustomerServicePluginState.Disabled,
    pluginStateName: model.servicePluginState ? CustomerServicePluginStateName.Enabled : CustomerServicePluginStateName.Disabled,
    notice: model.notice
  };
}

export function toCustomerPluginViewModels(models: CustomerServicePluginModel[]): CustomerServicePluginViewModel[] {
  return models.map(x => {
    return toCustomerPluginViewModel(x);
  });
}
