import { environment } from "src/environments/environment";
import { FormatDateTimeType } from "../enums/format-datetime-type.enum";
import { CookieStorage } from "./cookie-storage";
import * as moment from 'moment';
import { Constants } from "../constants/constant";
import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
 export class AppDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            let day: string = date.getDate().toString();
            day = +day < 10 ? `0${day}` : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? `0${month}` : month;
            const year = date.getFullYear();
            let langCode = new CookieStorage().get(Constants.LANGUAGE_CULTURE);
            if (!langCode) {
                langCode = environment.defaultLanguage;
            }
            return langCode === 'no' ? `${day}.${month}.${year}` : `${month}/${day}/${year}`;
        }

        return date.toDateString();
    }
}
export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

export function formatDateFromUTCToLocal(date: Date, type: FormatDateTimeType = FormatDateTimeType.Date) {
  let langCode = new CookieStorage().get(Constants.LANGUAGE_CULTURE);

  if (!langCode) {
    langCode = environment.defaultLanguage;
  }

  let formatDateTime = '';
  switch (type) {
    case FormatDateTimeType.DateAndTime:
      if (langCode === 'en') {
        formatDateTime = 'MM/DD/YYYY hh:mm:ss A';
      } else {
        formatDateTime = 'DD.MM.YYYY HH:mm:ss';
      }

      break;
    case FormatDateTimeType.DateAndTimeWithOutSeconds:
      if (langCode === 'en') {
        formatDateTime = 'MM/DD/YYYY hh:mm A';
      } else {
        formatDateTime = 'DD.MM.YYYY HH:mm';
      }
      break;
    case FormatDateTimeType.TimeAndDateWithoutSeconds:
      if (langCode === 'en') {
        formatDateTime = 'hh:mm A - MM/DD/YYYY';
      } else {
        formatDateTime = 'HH:mm - DD.MM.YYYY';
      }
    break;
    case FormatDateTimeType.Date:
    default:
      if (langCode === 'en') {
        formatDateTime = 'MM/DD/YYYY';
      } else {
        formatDateTime = 'DD.MM.YYYY';
      }
  }
  return moment.utc(date).local().format(formatDateTime);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function splitDateString(datetimeString: string): any[] {
  let result: string[] = [];
  const parts = datetimeString.split(' ');
  const dateString = parts[0];
  const timeString = parts.length > 1 ? parts[1] : '';
  if (dateString.indexOf('.') > -1) {
    result = dateString.split('.');
  } else if (dateString.indexOf('/') > -1) {
    result = dateString.split('/');
  } else if (dateString.indexOf('-') > -1) {
    result = dateString.split('-');
  }
  if (timeString !== '' && timeString.indexOf(':')) {
    timeString.split(':').forEach((i) => {
      result.push(i);
    });
  }
  return result;
}

export function toDate(dateString: string) {
  if (dateString === '' || dateString == null) {
    return new Date('01-01-1900');
  }
  const dateParts = splitDateString(dateString);
  if (dateParts.length > 2) {
    const year = dateParts[2];
    let month = dateParts[1] - 1;
    let date = dateParts[0];
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (dateString.indexOf('/') > -1) {
      month = dateParts[0] - 1;
      date = dateParts[1];
    }
    if (dateParts.length > 3) {
      hours = dateParts[3];
    }
    if (dateParts.length > 4) {
      minutes = dateParts[4];
    }
    if (dateParts.length > 5) {
      seconds = dateParts[5];
    }
    return new Date(year, month, date, hours, minutes, seconds, 0);
  }
  return new Date('01-01-1900');
}
