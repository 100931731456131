export enum CustomerType {
  Client = 0,
  Demo = 1
}

export enum CustomerInforType {
  Installation= 'app-customer-installations',
  StatusWebservice= 'app-customer-status-of-websites',
  ElmahLog='app-customer-elmah-log',
  SSL = 'app-customer-ssl',
  StatusDatabase='app-customer-status-of-databases',
  StatusService = 'app-customer-status-of-services', 
  AdSyncStatus = 'app-customer-ad-sync-status'
}