import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AuthenticatedUserViewModel } from 'src/app/modules/auth/models/authenticated-user-view.model';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { BaseComponent } from '../../../base-component';
import { Pages } from '../../../constants/page.constant';
import { GlobalService } from '../../../services/global.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { IconModel } from '../../../models/icon.model';

@Component({
  selector: 'app-monitor-app-bar',
  templateUrl: './monitor-app-bar.component.html',
  styleUrls: ['./monitor-app-bar.component.scss']
})
export class MonitorAppBarComponent extends BaseComponent implements OnInit {
  isAuthenticated = false;
  user: AuthenticatedUserViewModel = {
    userId: '',
    firstName: '',
    roles: [],
    isAdmin: false
  }
  accountIcon = new IconModel('account_circle', '');
  languageIcon = new IconModel('language', '');
  rightIcons = [this.languageIcon, this.accountIcon];
  headerLogoPath: string = `//${window.location.host}/assets/images/netpower-icon-outline.png`;
  userRole: string | null = null;
  rightIcon: string = '';

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @Input() hideMenuDropdown: boolean = false;
  @Input() hideChangePasswordButton: boolean = false;
  @Output() onRightIconClicked = new EventEmitter<string>();
  @Output() onMenuClicked = new EventEmitter<void>();

  constructor(
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.hideMenuDropdown){
      this.headerLogoPath = `//${window.location.host}/assets/images/netpower-icon-main.png`;
    }
    this.globalService.getAuthencatedUserSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.user = res;
      this.userRole = this.user.roles && this.user.roles.length > 0 ? this.user.roles[0] : null;
    });
  }

  menuClick(e: any) {
    e.stopPropagation();
  }

  menuAppbarClick() {
    this.onMenuClicked.emit();
  }

  logout() {
    this.authService.logout().pipe(catchError(() => {
      return of();
    })).subscribe(() => {
      this.router.navigate([`/${Pages.auth}/${Pages.login}`]);
    });
  }

  changePassword() {
    this.router.navigate([`/${Pages.changePassword}`], { queryParams: { returnUrl: this.router.url } });
  }

  rightIconClick(icon: string) {
    this.onRightIconClicked.emit(icon);
    switch (icon) {
      case 'account_circle':
        this.rightIcon = 'account_circle';
        if (this.trigger) {
          this.trigger.toggleMenu();
        }
        break;
      case 'language':
        this.rightIcon = 'language';
        if (this.trigger) {
          this.trigger.toggleMenu();
        }
        break;
    }
  }
}
