import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Api } from '../../common/constants/api.constants';
import { OrderDirection } from '../../common/enums/order-direction.enum';
import { PagingResultModel } from '../../common/models/paging-result.model';
import { ResponseModel } from '../../common/models/response.model';
import { UserRoleModel } from '../../common/models/user-role.model';
import { BaseService } from '../../common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService {
  public getRoles(
    pageIndex: number = 0,
    pageSize: number = environment.pageSize,
    orderByName: string = '',
    orderByDirection: OrderDirection = OrderDirection.Ascending,
    searchText: string| null = null
    ) : Observable<PagingResultModel<UserRoleModel[]>> {
    const params = this.buildPagingParameters({
      pageIndex: pageIndex,
      pageSize: pageSize,
      orderBy: {
        name: orderByName,
        direction: orderByDirection
      },
      filter: {
        searchText: searchText
      }
    });
    return this.get(Api.role.base, {
      ...params
    });
  }

  public getAllRoles(): Observable<UserRoleModel[]> {
    return this.get( Api.role.getAll);
  }


  public getRole(id: string): Observable<ResponseModel<UserRoleModel>> {
    const url = Api.role.get.replace(Api.role.id, id);
    return this.get(url);
  }

  public createRole(role: UserRoleModel): Observable<UserRoleModel> {
    return this.post(Api.role.base, { ...role});
  }

  public updateRole(id: string, role: UserRoleModel): Observable<ResponseModel<UserRoleModel>> {
    const url = Api.role.update.replace(Api.role.id, id);
    return this.put(url, { ...role});
  }

  public deleteRole(id: string): Observable<ResponseModel<boolean>> {
    const url = Api.role.delete.replace(Api.role.id, id);
    return this.delete(url);
  }

}
