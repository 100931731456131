
export class EmailForCustomerDataModel{
    customerName: string;
    serviceName: string;
    problemOccur?: string;
    adSyncMessage?: string;
    pluginMessage?: string;
    constructor(){
        this.customerName = '';
        this.problemOccur = '';
        this.serviceName = '';
    }
}
