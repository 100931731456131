import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
// import * as moment from 'moment';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
@Injectable({
  providedIn: 'root'
})
export class CookieStorage {
  private secrectKey = 'secrectKey.process.Key';
  private options = { path: '/' };

  private encrypt(data: string) {
    try {
      if (!data) {
        return '';
      }

      const ciphertext = CryptoJS.AES.encrypt(data, this.secrectKey);
      return ciphertext.toString();
    } catch {
      return data;
    }
  }

  private decrypt(data: string) {
    if (!data) {
      return '';
    }

    const bytes = CryptoJS.AES.decrypt(data, this.secrectKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }

  set(key: string, value: string, encrypted: boolean = false) {
    let newValue = value;
    if (encrypted) {
      newValue = this.encrypt(value);
    }
    cookies.set(key, newValue, { ...this.options });
  }

  get(key: string, decrypted: boolean = false) {
    if (decrypted) {
      return this.decrypt(cookies.get(key));
    }
    return cookies.get(key);
  }

  remove(key: string) {
    cookies.remove(key, { ...this.options });
  }
}
