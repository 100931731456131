import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TranslateLibraryService } from 'qms-angular';
import { BaseComponent } from '../../../base-component';
import { Constants } from '../../../constants/constant';
import { SvgIcons } from '../../../constants/svg-icons.constant';
import { Language } from '../../../enums/language.enum';
import { CookieStorage } from '../../../helpers/cookie-storage';
import { GlobalService } from '../../../services/global.service';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent extends BaseComponent implements OnInit {
  currentLang: Language | undefined;
  @Output() change = new EventEmitter<Language>();

  constructor(
    private translateQMS: TranslateLibraryService,
    private cookieHelper: CookieStorage,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer  ) {
    super();
    let defaultLanguage = this.cookieHelper.get(Constants.LANGUAGE_CULTURE);
    defaultLanguage = defaultLanguage ? defaultLanguage : this.environment.defaultLanguage;
    this.setLanguage(defaultLanguage);
    this.iconRegistry.addSvgIconLiteral('no-flag', this.sanitizer.bypassSecurityTrustHtml(SvgIcons.NO_FLAG));
    this.iconRegistry.addSvgIconLiteral('gb-flag', this.sanitizer.bypassSecurityTrustHtml(SvgIcons.GB_FLAG));
  }

  public get language() {
    return Language;
  }

  ngOnInit(): void {

  }

  setLanguage(lang: Language) {
    this.currentLang = lang;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.translateQMS.setLanguage(lang);
    this.cookieHelper.set(Constants.LANGUAGE_CULTURE, this.currentLang);
    this.change.emit(this.currentLang);
  }
}
