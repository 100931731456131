<div class="home-container">
  <app-monitor-app-bar (onMenuClicked)="onMenuClicked()"></app-monitor-app-bar>
  <div class="row flex-container ms-0 me-0 app-container">
    <div class="col ps-0 pe-0">
      <mat-sidenav-container class="navigation-drawer-container">
        <mat-sidenav qms-drawer opened #drawer class="navigation-drawer" [mode]="isMobile ? 'over' : 'side'"
          [autoFocus]="true">
          <div qms-drawer-content qms-scrollbar>
            <mat-nav-list class="menus">
              <mat-list-item routerLink="/{{pages.home}}" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <mat-icon class="material-icons-outlined" matListIcon>home</mat-icon>
                <a matLine>{{'SIDE_BAR.HOME' | translate}}</a>
              </mat-list-item>
              <mat-list-item routerLink="/{{pages.users}}" routerLinkActive="active" *ngIf="user.isAdmin"
                [routerLinkActiveOptions]="{ exact: true }">
                <mat-icon class="material-icons-outlined" matListIcon>person</mat-icon>
                <a matLine>{{'SIDE_BAR.USERS' | translate}}</a>
              </mat-list-item>
              <mat-list-item routerLink="/{{pages.roles}}" routerLinkActive="active" *ngIf="user.isAdmin"
                [routerLinkActiveOptions]="{ exact: true }">
                <mat-icon class="material-icons-outlined" matListIcon>lock</mat-icon>
                <a matLine>{{'SIDE_BAR.ROLES' | translate}}</a>
              </mat-list-item>
            </mat-nav-list>
            <div class="footer">
              <div class="logo">
                <img [src]="footerLogoPath" alt="logo" />
              </div>
              <div class="footer-note">
                <span>© Netpower Kvalitet {{year}} – {{'COMMON.V' | translate}} {{environment.version}}</span>
              </div>
            </div>
          </div>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="col ps-0 pe-0 h-100">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
</div>
