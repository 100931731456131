import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {catchError, of, takeUntil, tap} from "rxjs";
import { BaseComponent } from "../../common/base-component";
import { Constants } from "../../common/constants/constant";
import { Pages } from "../../common/constants/page.constant";
import { SvgIcons } from "../../common/constants/svg-icons.constant";
import { Language } from "../../common/enums/language.enum";
import { CookieStorage } from "../../common/helpers/cookie-storage";
import { GlobalService } from "../../common/services/global.service";
import { ToastService } from "../../common/services/toast.service";
import { AuthService } from "../services/auth.service";
import {SSOTokenData} from "../models/authenticated-user-view.model";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  returnUrl: string = '';
  username: string = '';
  password: string = '';
  currentLang: string = '';
  showPassword: boolean = false;
  footerLogoPath: string = `//${window.location.host}/assets/images/netpower-logo.png`;
  loginForm: FormGroup = new FormGroup({});
  isLoginFailed: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private cookieHelper: CookieStorage,
  ) {
    super();
    if (this.authService.isAuthenticated()) {
      this.router.navigate([Pages.root]);
    }
    this.iconRegistry.addSvgIconLiteral('no-flag', this.sanitizer.bypassSecurityTrustHtml(SvgIcons.NO_FLAG));
    this.iconRegistry.addSvgIconLiteral('gb-flag', this.sanitizer.bypassSecurityTrustHtml(SvgIcons.GB_FLAG));
    this.onLoginViaToken();
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = new FormGroup({
      username: new FormControl(this.username, [Validators.required, Validators.email]),
      password: new FormControl(this.password, [Validators.required]),
    });
    let defaultLanguage = this.cookieHelper.get(Constants.LANGUAGE_CULTURE);
    this.currentLang = defaultLanguage ? defaultLanguage : this.environment.defaultLanguage;
  }

  public get language(){
    return Language;
  }

  togglePasswordType() {
    this.showPassword = !this.showPassword;
  }

  languageChange(lang: Language){
    this.currentLang = lang;
  }

  async login(sso: boolean = false) {
    if (!sso) {
      this.loginForm.markAllAsTouched();
      if (this.loginForm.valid) {
        this.isLoginFailed = false;
        this.authService.login(this.username, this.password)
          .pipe(takeUntil(this.ngUnsubscribe), catchError((error) => {
            if (error.status == 401 || error.status == 403 || error.status == 400) {
              ToastService.error(this.translate.instant('ERROR.USERNAME_OR_PASSWORD_IS_INCORRECT'))
            }
            return of();
          }))
          .subscribe(res => {
            if (res && res.token) {
              this.router.navigate([this.returnUrl]);
            }
          });
      }
      return;
    }
    const returnPath = this.router.routerState.snapshot.url;
    const ssoUrl = new URL('', `${environment.ssoLoginUrl}${window.location.href}&ssoCallbackPath=${returnPath}`);
    window.open(ssoUrl, '_self');
  }

  private onLoginViaToken(){
    this.route.queryParams.pipe(
      takeUntil(this.ngUnsubscribe),
      tap((res: SSOTokenData) =>{
        if(res?.token){
          this.authService.loginViaSSOToken(res.token)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              catchError((error) => {
              return of();
            }))
            .subscribe(res => {
              if(res && res.token) {
                this.router.navigate([this.returnUrl]);
              }
            });
        }
      })
    ).subscribe()
  }
}
