import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QMSButtonModule, QmsAppBarModule, QMSListModule, QmsAngularModule } from 'qms-angular';
import { LoadingInterceptorService } from './services/loading-interceptor.service';
import { LoadingService } from './services/loading.service';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/timeout-interceptor.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoadingComponent } from './directives/layouts/loading/loading.component';
import { LanguageComponent } from './directives/layouts/language/language.component';
import { QmsCkEditorFocusDirective } from './directives/events/qms-ckeditor-focus.directive';
import { DynamicComponentDirective } from './directives/events/dynamic-component.directive';
import { MonitorAppBarComponent } from './directives/layouts/monitor-app-bar/monitor-app-bar.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { QmsDatePipe } from './pipes/qms-date.pipe';
import { PreviewMailNotifyCustomerComponent } from './components/preview-mail-notify-customer/preview-mail-notify-customer.component';

@NgModule({
  declarations: [
    MonitorAppBarComponent,
    DynamicComponentDirective,
    QmsCkEditorFocusDirective,
    LoadingComponent,
    LanguageComponent,
    MessageBoxComponent,
    QmsDatePipe,
    PreviewMailNotifyCustomerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatDialogModule,
    MatSidenavModule,
    QMSButtonModule,
    QmsAppBarModule,
    QMSListModule
  ],
  exports: [
    MonitorAppBarComponent,
    DynamicComponentDirective,
    QmsCkEditorFocusDirective,
    LanguageComponent,
    LoadingComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    QmsAngularModule,
    QmsAppBarModule,
    QMSListModule,
    QmsDatePipe,
    PreviewMailNotifyCustomerComponent
  ],
  providers: [
    TranslateService,
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true
    },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 90000 }]
  ]
})
export class AppCommonModule { }
