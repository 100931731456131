<div class="login-container h-100">
  <div class="login">
    <div class="login-header">
      <img [src]="footerLogoPath" alt="logo" />
    </div>
    <div class="login-content">
      <div class="login-content-header">
        <h1 class="login-title">{{'AUTH.SIGN_IN' | translate}}</h1>
        <p class="login-subtitle">{{'AUTH.SIGN_IN_TO_STAY_CONNECTED' | translate}}</p>
      </div>
      <div class="login-content-body mt-4" [formGroup]="loginForm">
        <mat-form-field qms-form appearance="fill" class="w-100">
          <mat-label>{{'COMMON.EMAIL' | translate}}</mat-label>
          <mat-icon class="material-icons-outlined" matPrefix>email</mat-icon>
          <input matInput [(ngModel)]="username" formControlName="username" type="email" autocomplete="off">
          <mat-error *ngIf="loginForm.get('username')?.hasError('required')">
            {{ 'ERROR.THIS_FIELD_IS_REQUIRED' | translate}}
          </mat-error>
          <mat-error *ngIf="loginForm.get('username')?.hasError('email')">
            {{ 'ERROR.INVALID_EMAIL_ADDRESS' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field qms-form appearance="fill" class="w-100">
          <mat-label>{{'AUTH.PASSWORD' | translate}}</mat-label>
          <mat-icon class="material-icons-outlined" matPrefix>lock</mat-icon>
          <input matInput [(ngModel)]="password" formControlName="password" type="{{showPassword ? 'text': 'password'}}"
            autocomplete="off">
          <button mat-icon-button matSuffix (click)="togglePasswordType()"
            qms-tool-tip="{{ (showPassword ? 'AUTH.HIDE_PASSWORD' : 'AUTH.SHOW_PASSWORD') | translate }}" mode="dark">
            <mat-icon class="material-icons-outlined" *ngIf="showPassword">visibility_off</mat-icon>
            <mat-icon class="material-icons-outlined" *ngIf="!showPassword">visibility</mat-icon>
          </button>
          <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
            {{ 'ERROR.THIS_FIELD_IS_REQUIRED' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="login-content-footer">
        <!-- <a matLine routerLink="/home">{{'AUTH.FORGOT_PASSWORD' | translate}}</a> -->
      </div>
    </div>
    <div class="login-footer d-flex flex-column gap-2 align-items-center">
      <button type="button" mat-flat-button (click)="login(true)">
        {{'AUTH.SIGN_IN_VIA_SSO' | translate}}
      </button>
      <button type="button" mat-flat-button (click)="login()">
        {{'AUTH.SIGN_IN' | translate}}
      </button>
    </div>
    <div class="col pt-3">
      <button mat-button [matMenuTriggerFor]="menu">
        <span *ngIf="currentLang == language.Norway">Norsk</span>
        <span *ngIf="currentLang == language.English">English</span>
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
         <app-language (change)="languageChange($event)"></app-language>
      </mat-menu>
    </div>
  </div>
</div>
