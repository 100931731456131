import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../common/services/base.service';
import { Observable, of, tap } from 'rxjs';
import { UserLoginModel } from '../../common/models/user-login.model';
import { CookieStorage } from '../../common/helpers/cookie-storage';
import { Constants } from '../../common/constants/constant';
import { RefreshTokenModel } from '../../common/models/refresh-token.model';
import { AuthenticatedUserModel } from '../../common/models/authenticated-user.model';
import { ResponseModel } from '../../common/models/response.model';
import { Api } from '../../common/constants/api.constants';

@Injectable()
export class AuthService extends BaseService {
  constructor(
    public override http: HttpClient,
    public jwtHelper: JwtHelperService,
    private cookieHelper: CookieStorage,
  ) {
    super(http);
  }

  public getRefreshToken(): string {
    return this.cookieHelper.get(Constants.JWT_REFRESH_TOKEN) as string;
  }

  public setRefreshToken(token: string): void {
    this.cookieHelper.set(Constants.JWT_REFRESH_TOKEN, token);
  }

  public removeRefreshToken(): void {
    this.cookieHelper.remove(Constants.JWT_REFRESH_TOKEN);
  }

  public getAccessToken(): string {
    return this.cookieHelper.get(Constants.JWT_ACCESS_TOKEN) as string;
  }

  public setAccessToken(token: string): void {
    this.cookieHelper.set(Constants.JWT_ACCESS_TOKEN, token);
  }

  public removeAccessToken(): void {
    this.cookieHelper.remove(Constants.JWT_ACCESS_TOKEN);
  }

  public removeTokens(): void {
    this.removeAccessToken();
    this.removeRefreshToken();
  }

  public isAuthenticated(): boolean {
    try {
      return !this.jwtHelper.isTokenExpired(this.getAccessToken());
    } catch {
      return false;
    }
  }

  public login(
    userName: string,
    password: string
  ): Observable<UserLoginModel> {
    const params: any = {
      userName: userName,
      password: password
    };

    return this.post(Api.auth.login, { ...params }).pipe(tap({
      next: (res: UserLoginModel) => {
        if (res.token) {
          this.setAccessToken(res.token);
        }
        if (res.refreshToken) {
          this.setRefreshToken(res.refreshToken);
        }
      }
    }));
  }

  public loginViaSSOToken(token: string): Observable<UserLoginModel> {
    return this.post(`${Api.auth.login}/${token}`, null).pipe(
      tap({
      next: (res: UserLoginModel) => {
        if (res.token) {
          this.setAccessToken(res.token);
        }
        if (res.refreshToken) {
          this.setRefreshToken(res.refreshToken);
        }
      }
    }));
  }

  public refreshToken(): Observable<ResponseModel<RefreshTokenModel>> {
    const refreshToken = this.getRefreshToken();
    const params: any = {
      refreshToken: refreshToken
    };

    return this.post(Api.auth.refreshToken, { ...params }).pipe(tap({
      next: (res: ResponseModel<RefreshTokenModel>) => {
        if (res.data.token) {
          this.setAccessToken(res.data.token);
        }
        if (res.data.refreshToken) {
          this.setRefreshToken(res.data.refreshToken);
        }
      }
    }));
  }

  public logout(): Observable<ResponseModel<boolean>> {
    const refreshToken = this.getRefreshToken();
    const params: any = {
      refreshToken: refreshToken
    };
    return this.post(Api.auth.revokeToken, { ...params }).pipe(tap({
      finalize: () => {
        this.removeTokens();
      }
    }));
  }

  public getAuthenticatedUser(): Observable<AuthenticatedUserModel> {
    return this.get(Api.auth.userInformation);
  }

  public changePassword(
    password: string,
    confirmPassword: string
  ): Observable<boolean> {
    const params: any = {
      password: password,
      confirmPassword: confirmPassword
    };

    return this.post(Api.auth.changePassword, { ...params });
  }

}
