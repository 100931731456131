<qms-list class="language-container">
  <div mat-menu-item>
    <qms-list-item (click)="setLanguage(language.Norway)">
      <mat-icon leading-icon svgIcon="no-flag"></mat-icon>
      <div qms-list-header>
        <div qms-line type="subtitle" size="sm">Norsk</div>
      </div>
      <button mat-icon-button class="ps-2 pe-2" *ngIf="currentLang == language.Norway">
        <mat-icon>check</mat-icon>
      </button>
      <button mat-icon-button class="ps-2 pe-2" *ngIf="currentLang == language.English">
        <mat-icon></mat-icon>
      </button>
    </qms-list-item>
  </div>
  <div mat-menu-item>
    <qms-list-item (click)="setLanguage(language.English)">
      <mat-icon leading-icon svgIcon="gb-flag"></mat-icon>
      <div qms-list-header>
        <div qms-line type="subtitle" size="sm">English</div>
      </div>
      <button mat-icon-button class="ps-2 pe-2" *ngIf="currentLang == language.Norway">
        <mat-icon></mat-icon>
      </button>
      <button mat-icon-button class="ps-2 pe-2" *ngIf="currentLang == language.English">
        <mat-icon>check</mat-icon>
      </button>
    </qms-list-item>
  </div>
</qms-list>
