import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs";
import { BaseComponent } from "../../common/base-component";
import { Pages } from "../../common/constants/page.constant";
import { GlobalService } from "../../common/services/global.service";
import { AuthService } from "../services/auth.service";
import * as modelMapper from "../../common/helpers/model-mapper";
import { confirmedValidator } from "../../common/helpers/validator";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {
  password: string = '';
  confirmPassword: string = '';
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  changePasswordForm: FormGroup = new FormGroup({});
  isFailed: boolean = false;
  returnUrl: string = Pages.root;
  footerLogoPath: string = `//${window.location.host}/assets/images/netpower-logo.png`;

  constructor(
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService,
    private route: ActivatedRoute
  ) {
    super();
    if (!this.authService.isAuthenticated()) {
      this.router.navigate([`/${Pages.login}`]);
    }
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Pages.root;
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      confirmPassword: new FormControl(null, [Validators.required, confirmedValidator])
    });
    this.getAuthenticatedUser();
  }

  getAuthenticatedUser() {
    this.authService.getAuthenticatedUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.globalService.setAuthenticatedUser(modelMapper.toAuthenticatedUserViewModel(res));
    });
  }

  togglePasswordType() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordType() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  goBack() {
    this.router.navigate([this.returnUrl]);
  }

  changePassword() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      this.isFailed = false;
      this.authService.changePassword(this.password, this.confirmPassword)
        .subscribe(res => {
          if (res) {
            this.router.navigate([this.returnUrl]);
          }
        });
    }
  }
}
