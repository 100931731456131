<div *ngIf="data" class="email-template-container">
  <div class="logo-header">
    <mat-icon class="svg-logo-header" svgIcon="logo"></mat-icon>
  </div>
  <div class="header">
    <span class="title-text">Hi {{ data.customerName }}</span>
    <span class="title-text-note">{{ 'MESSAGE.TITLE_TEMPLATE_NOTIFY_MAIL' | translate }}</span>
  </div>
  <div class="body">
    <div class="body-header" id="bodyHeader">
      <mat-icon>monitor</mat-icon>
      <span class="pl-10 body-header-text">{{ 'SIDE_BAR.MONITORING' | translate }}</span>
    </div>
    <div class="body-content">
      <table>
        <tr>
          <th>
            {{ 'COMMON.NAME' | translate }}
          </th>
        </tr>
        <tr>
          <td>{{ data.serviceName}}</td>
        </tr>
      </table>
      <br />
      <span *ngIf="data.problemOccur" style="padding-top: 24px">
        <div class="body-content-title">{{ 'CUSTOMER.PROBLEM_OCCUR' | translate }}</div>
        <div class="body-content-text">{{ data.problemOccur }}</div>
      </span>
      <br />
      <div *ngIf="data.adSyncMessage" class="mb-2 w-100">
        <table>
          <tr>
            <th>
              {{ 'COMMON.ERROR_TYPE' | translate }}
            </th>
            <th>{{ 'COMMON.TIME' | translate }}</th>
          </tr>
          <tr>
            <td>{{ 'CUSTOMER.AD_SYNC_FAIL' | translate }}</td>
            <td>{{ currentDateTime }}</td>
          </tr>
        </table>
        <div class="body-content-title mt-2">{{ 'COMMON.MESSAGE' | translate }}</div>
        <span class="body-content-text">
          {{ data.adSyncMessage}}
        </span>
      </div>
      <div *ngIf="data.pluginMessage" class="mb-2 w-100">
        <table>
          <tr>
            <th>
              {{ 'COMMON.ERROR_TYPE' | translate }}
            </th>
            <th>{{ 'COMMON.TIME' | translate }}</th>
          </tr>
          <tr>
            <td>{{ 'CUSTOMER.SERVICE_PLUGGIN_FAIL' | translate }}</td>
            <td>{{currentDateTime}}</td>
          </tr>
        </table>
        <div class="body-content-title mt-2">{{ 'COMMON.MESSAGE' | translate }}</div>
        <span class="body-content-text">
          {{ data.pluginMessage}}
        </span>
      </div>
    </div>
  </div>
  <div class="footer mx-auto">
    <span class="footer-text">
      {{'MESSAGE.FOOTER_EMAIL_TEXT_1' | translate}}
    </span>
    <br />
    <span class="footer-text">
      {{'MESSAGE.FOOTER_EMAIL_TEXT_2' | translate}}
    </span>
  </div>
</div>
