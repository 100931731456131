import { CustomerServiceModuleStatus } from "../../common/enums/customer-service-module-status.enum";
import { CustomerServicePluginState } from "../../common/enums/customer-service-plugin-state.enum";

export class CustomerServicePluginModel {
    customerId?: string;
    customerName?: string;
    moduleCode?: string;
    pluginId?: string;
    pluginName?: string;
    notice?: string;
    servicePluginState: boolean;
    serviceStatus: boolean;
    constructor() {
        this.servicePluginState = false;
        this.serviceStatus = false;
    }
}

export class CustomerServicePluginViewModel extends CustomerServicePluginModel {
    serviceStatusView?: CustomerServiceModuleStatus;
    servicePluginStateView?: CustomerServicePluginState;
    pluginStateName?: string;
    serviceStatusName?: string;
}

export class PluginRequest {
    customerId?: string;
    moduleCode?: string;
}