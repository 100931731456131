import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateLibraryService } from 'qms-angular';
import { AuthService } from './modules/auth/services/auth.service';
import { BaseComponent } from './modules/common/base-component';
import { Constants } from './modules/common/constants/constant';
import { CookieStorage } from './modules/common/helpers/cookie-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  globalLoading = true;

  constructor(
    private translateQMS: TranslateLibraryService,
    private cookieHelper: CookieStorage,
    public authService: AuthService,
    public router: Router
  ) {
    super();
    let currentLang = this.cookieHelper.get(Constants.LANGUAGE_CULTURE);
    currentLang = currentLang ? currentLang : this.environment.defaultLanguage;
    this.translate.setDefaultLang(currentLang);
    this.translate.use(currentLang);
    this.translateQMS.setLanguage(currentLang);
  }

  ngOnInit(): void {
    this.globalLoading = this.environment.globalLoading;
  }
}
