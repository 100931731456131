import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateTimeType } from '../enums/format-datetime-type.enum';
import { formatDateFromUTCToLocal } from '../helpers/datetime-helper';

@Pipe({
  name: 'qmsDate'
})
export class QmsDatePipe implements PipeTransform {
  transform(value: Date, format?: FormatDateTimeType, lang?: any) {
    if (!value) {
      return '';
    }
    format = format || FormatDateTimeType.Date;
    return formatDateFromUTCToLocal(value, format);
  }
}
