import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../base-component';
import { LoadingService } from '../../../services/loading.service';


@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent extends BaseComponent implements AfterViewInit {
    isShowLoading = true;

    constructor(
        private loadingService: LoadingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.loadingService.isLoading$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status: boolean) => {
            this.isShowLoading = status;
            this.changeDetectorRef.detectChanges();
        });
    }
}
