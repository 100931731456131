export const Api = {
  auth: {
    base: 'auth',
    login: 'auth/login',
    loginViaSSOToken: 'login',
    revokeToken: 'auth/revoke-token',
    refreshToken: 'auth/refresh-token',
    userInformation: 'auth/user-information',
    changePassword: 'auth/change-password',
  },
  customer: {
    id: '{id}',
    base: 'customer',
    customerInstallation: 'customer/get-customer-installation',
    customerDatabaseStatus: 'customer/get-customer-database-status',
    elmahLog: 'customer/{id}/elmah-logs',
    get: 'customer/{id}',
    update: 'customer/{id}',
    delete: 'customer/{id}',
    lastestUpdateInstallationSchedule: 'customer/get-lastest-update-schedule/{id}',
    updateCustomerInstallationSchedule: 'customer/update-schedule',
    lastSyncStatus: 'customer/get-customers-ad-sync-status',
    adSyncLogs: 'customer/{id}/ad-sync-logs',
    updateCustomerNotify: 'customer/update-notify-customer',
    getCustomerNotify: 'customer/get-customer-notify/{id}',
  },
  customerDemo: {
    id: '{id}',
    base: 'customerdemo',
    update: 'customerdemo/{id}',
  },
  customerSite: {
    base: 'customerServiceSite',
    statusOfWebsites: 'customerServiceSite/get-status-of-websites',
    ssl: 'customerServiceSite/get-ssl'
  },
  user: {
    id: '{id}',
    base: 'user',
    get: 'user/{id}',
    update: 'user/{id}',
    delete: 'user/{id}'
  },
  role: {
    id: '{id}',
    base: 'role',
    get: 'role/{id}',
    getAll: 'role/all',
    create: 'role/{id}',
    update: 'role/{id}',
    delete: 'role/{id}'
  },
  importantMessage: {
    id: '{id}',
    base: 'importantMessage',
    countOfAllTypes: 'importantMessage/count/all-types',
    hideMessageById: 'importantMessage/{id}/hidden'
  },
  comment: {
    id: '{id}',
    base: 'comment',
    get: 'comment/{id}',
    getAll: 'comment/all',
    create: 'comment',
    update: 'comment/{id}',
    delete: 'comment/{id}',
    pin: 'comment/pin'
  },
  customerStatus:{
    id: '{id}',
    getServiceModule:'customerStatusService/{id}/get-service-module',
    getServicePlugin:'customerStatusService/get-service-plugin',
    getServicePluginInfo:'customerStatusService/{id}/get-service-plugin-info',
    updateServicePluginNotice:'customerStatusService/update-service-plugin-notice'
  }
}
