import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { parseJwt } from '../../common/helpers/jwt-helper';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../common/services/toast.service';
import { Pages } from '../../common/constants/page.constant';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router,
    private translate: TranslateService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedRole = route.data['expectedRole'];
    const token = this.authService.getAccessToken();
    const tokenPayload: any = parseJwt(token);
    const isAuthenticated = this.authService.isAuthenticated();
    const isAdmin = tokenPayload.role == expectedRole;
    if (!isAuthenticated || !isAdmin) {
      ToastService.error(this.translate.instant('ERROR.401'));
      this.authService.removeTokens();
      this.router.navigate([`/${Pages.auth}/${Pages.login}`], { queryParams: { returnUrl: route.url } });
      return false;
    }
    return true;
  }
}
