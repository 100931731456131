import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Pages } from './modules/common/constants/page.constant';
import { AuthGuardService as AuthGuard } from './modules/auth/services/auth-guard.service';
import { RoleChildrenGuardService as RoleChildrenGuard } from './modules/auth/services/role-children-guard.service';
import { HomeComponent } from './modules/home/home.component';
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
    children: [
      {
        path: Pages.home,
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: '',
        canActivateChild: [RoleChildrenGuard],
        data: {
          expectedRole: 'admin'
        },
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      }
    ],
  },
  {
    path: Pages.auth,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  // {
  //   path: Pages.user,
  //   canActivate: [AuthGuard],
  //   canActivateChild: [RoleChildrenGuard],
  //   data: {
  //     expectedRole: 'admin'
  //   },
  //   component: HomeComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  //     }
  //   ],
  // },
  // {
  //   path: '',
  //   redirectTo: Pages.home,
  //   pathMatch: 'full'
  // },
  {
    path: "**",
    redirectTo: Pages.home
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    enableTracing: false,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
