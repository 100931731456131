import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppInjector } from './modules/common/services/app-injector.service';
import { AppCommonModule } from './modules/common/app-common.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { AuthModule } from './modules/auth/auth.module';
import { HomeModule } from './modules/home/home.module';

export function TranslationLoaderFactory(http: HttpClient) {
  let resourcePath = '';
  if (environment && environment.assetsPath) {
    resourcePath = `${environment.assetsPath}/i18n/`;
  } else {
    resourcePath = `${window.location.origin}/assets/i18n/`;
  }
  return new TranslateHttpLoader(http, resourcePath, '.json');
}


export function StartupServiceFactory(http: HttpClient) {
  return () => {

  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppCommonModule,
    HomeModule,
    AuthModule,
    AppRoutingModule,
    MatTableModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule
  ],
  providers: [
    {
        provide: APP_INITIALIZER,
        useFactory: StartupServiceFactory,
        deps: [HttpClient],
        multi: true
    }
],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injectors: Injector) {
    AppInjector.setInjector(this.injectors);
  }
}
