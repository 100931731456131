import { JwtToken } from "../models/jwt-token.model";
import decode from 'jwt-decode';
export function parseJwt(token: string): JwtToken {
  try {
    const jwtToken: any = decode(token) || { userId: "", role: "" };
    return jwtToken;
  }
  catch {
    return { userId: "", role: "" };
  }
}

